var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              items: _vm.conversion,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "show-expand": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.nickname",
                fn: function ({ item }) {
                  return [
                    _vm._v(" " + _vm._s(item.user ? item.user.nickname : "")),
                  ]
                },
              },
              {
                key: "item.userid",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.user ? item.user.id : ""))]
                },
              },
              {
                key: "item.created_at",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          new Date(Date.parse(item.created_at)).toLocaleString()
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.convert_from",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(_vm.money_from2(item)))]
                },
              },
              {
                key: "item.convert_to",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(_vm.money_to2(item)))]
                },
              },
              {
                key: "item.commission",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(item.commission) +
                        " " +
                        _vm._s(item.cryptocurrencyByCryptoIdTo.title)
                    ),
                  ]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ headers, item }) {
                  return [
                    _c("td", { attrs: { colspan: headers.length - 2 } }, [
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            "Баланс валюты исх " +
                              _vm._s(item.cryptocurrency.title) +
                              " " +
                              _vm._s(item.balance_before_from) +
                              " -> " +
                              _vm._s(item.balance_after_from) +
                              " "
                          ),
                        ]),
                        _c("br"),
                      ]),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            "Баланс валюты начисления " +
                              _vm._s(item.cryptocurrencyByCryptoIdTo.title) +
                              " " +
                              _vm._s(item.balance_before_to) +
                              " -> " +
                              _vm._s(item.balance_after_to)
                          ),
                        ]),
                        _c("br"),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Withdraws" }, [
      _c("h3", [_vm._v("Конвертации")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }