<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Withdraws">
      <h3>Конвертации</h3>
    </div>

    <div>
      <v-data-table
          :items="conversion"
          :headers="headers"
          class="elevation-1"
          sort-by="id"
          sort-desc
          show-expand

          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.nickname="{ item }"> {{ item.user ? item.user.nickname : "" }}</template>
        <template v-slot:item.userid="{ item }"> {{ item.user ? item.user.id : "" }}</template>
        <template v-slot:item.created_at="{ item }"> {{
            new Date(Date.parse(item.created_at)).toLocaleString()
          }}
        </template>


        <template v-slot:item.convert_from="{ item }"> {{ money_from2(item) }}</template>
        <template v-slot:item.convert_to="{ item }"> {{ money_to2(item) }}</template>

        <template v-slot:item.commission="{ item }"> {{ item.commission }} {{item.cryptocurrencyByCryptoIdTo.title}}</template>



        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length-2">

          <!--span>Размер комисии {{item.comission}} %  </span-->

          <div>
            <span>Баланс валюты исх  {{
                item.cryptocurrency.title
              }} {{ item.balance_before_from }} -> {{ item.balance_after_from }}  </span><br>
          </div>

          <div>

            <span>Баланс валюты начисления  {{
                item.cryptocurrencyByCryptoIdTo.title
              }} {{ item.balance_before_to }} -> {{ item.balance_after_to }}</span><br>

          </div>
          </td>

        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>

import gql from "graphql-tag";

import Snackbar from "../components/controls/Snackbar.vue";

//const RestApiUrl = process.env.VUE_APP_REST_API_URL_ADMIN + "/user"

export default {
  name: 'ExchangeHistory',

  components: {Snackbar},

  apollo: {
    $subscribe: {
      conversions: {
        query: gql`

subscription MyQuery {
  conversions(order_by: {id: desc}) {
    id
    commission
    created_at
    user_id

    user {
      id
      nickname
    }

    cryptocurrency {
      title
    }
    cryptocurrencyByCryptoIdTo {
      title
    }

    amount_from
    amount_to

    balance_after_from
    balance_before_from

    balance_after_to
    balance_before_to

  }
}

        `,
        result({data}) {
          this.conversion = data.conversions;
        },
      },
    },
  },


  data: () => ({
    tag: 'test',
    conversion: [],

    headers: [
      {value: "id", text: "ID"},
      {value: "nickname", text: "Никнейм"},

      {value: "convert_from", text: "Валюта исходная"},
      {value: "convert_to", text: "Валюта начисления"},

      {value: "userid", text: "ID пользователя"},
      {value: "created_at", text: "Дата создания"},

      {value: "commission", text: "Комиссия"},


      //   {value: "money_from", text: "Валюта исходная"},
      //   {value: "money_to", text: "Валюта начисления"},
      //{value: "comission", text: "Комиссия"},
    ],
  }),

  methods: {

    getConvValue(item, currency, old) {

      let s = `balance_${currency}_${old ? "old" : "now"}`

      let n = item[s]

      if (!(s in item))
        n = item[s.toLowerCase()]

      return n
    },

    getConvValueFrom(item) {

      //const regex = /balance_(.*?)_old/;
      //const match = inputString.match(regex);
      //return match ? match[1] : null;

      //balance_ETH_old

      const s = `balance_${item.convert_from}_old`

      let n = item[s]

      if (!(s in item))
        n = item[s.toLowerCase()]

      return n
    },

    getConvValueTo(item) {
      const s = `balance_${item.convert_to}_now`

      let n = item[s]

      if (!(s in item))
        n = item[s.toLowerCase()]


      //balance_ETH_old
      return n

    },


    amount_usdt(item) {
      return Math.abs(item.balance_usdt_old - item.balance_usdt_now)
    },

    amount_btc(item) {
      return Math.abs(item.balance_btc_old - item.balance_btc_now)
    },


    money_from2(item) {
      return Math.abs(item.amount_from) + " " + item.cryptocurrency.title
    },

    money_to2(item) {
      return Math.abs(item.amount_to) + " " + item.cryptocurrencyByCryptoIdTo.title
    },

    money_from(item) {
      if (item.convert_to === "USDT")   //from btc
        return this.amount_btc(item) + " BTC"
      else
        return this.amount_usdt(item) + " USDT"
    },

    money_to(item) {
      if (item.convert_to === "USDT") //from usdt
        return this.amount_usdt(item) + " USDT"
      else
        return this.amount_btc(item) + " BTC"
    },

    toFixed2(num) {
      return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },

  },

}
</script>
